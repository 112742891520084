/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'tm1PxcRDvh4fJ6uC2aGut1.png': { uri: '/images/tm1PxcRDvh4fJ6uC2aGut1.png' },
'rFbrCV8YzWqQUGLhhefGsT.png': { uri: '/images/rFbrCV8YzWqQUGLhhefGsT.png' },
'gXWdBSNZnRfg8ipWhPtihG.png': { uri: '/images/gXWdBSNZnRfg8ipWhPtihG.png' },
'8iqNP7u1Hn4eMnVkPXpZo4.jpg': { uri: '/images/8iqNP7u1Hn4eMnVkPXpZo4.jpg' },
'91Uvf5rkHamHUYt3pAFVYg.png': { uri: '/images/91Uvf5rkHamHUYt3pAFVYg.png' },
'qyZ5HpDRQGDSBbrgPjpksY.png': { uri: '/images/qyZ5HpDRQGDSBbrgPjpksY.png' },
'5STTtbMGtpyCGNLsTDsUHB.png': { uri: '/images/5STTtbMGtpyCGNLsTDsUHB.png' },
'5wGPZATCnL3h37ouRWGd7M.png': { uri: '/images/5wGPZATCnL3h37ouRWGd7M.png' },
'dr2xk9ECheYTcJW5FEJJnD.png': { uri: '/images/dr2xk9ECheYTcJW5FEJJnD.png' },
'j1R7aAKzHiGhDpnkuk9CcV.png': { uri: '/images/j1R7aAKzHiGhDpnkuk9CcV.png' },
'1SyRzghLLKSDJjXtpxCqbu.png': { uri: '/images/1SyRzghLLKSDJjXtpxCqbu.png' },
'qsCm5Vw5qnF6mwjC3iZRq8.png': { uri: '/images/qsCm5Vw5qnF6mwjC3iZRq8.png' },
'mjynXApVH351ghtWkHeqVN.png': { uri: '/images/mjynXApVH351ghtWkHeqVN.png' },
'rCBYUy6Cjj45BWhMnrBz2h.png': { uri: '/images/rCBYUy6Cjj45BWhMnrBz2h.png' },
'vgiNV4yg2Urp5H382aba4V.jpg': { uri: '/images/vgiNV4yg2Urp5H382aba4V.jpg' },
'64mpiQLby5XCo6eaGcc9p6.jpg': { uri: '/images/64mpiQLby5XCo6eaGcc9p6.jpg' },
'k8P13sPE8XE15R8jW34oG5.png': { uri: '/images/k8P13sPE8XE15R8jW34oG5.png' },
'dAiv3qfJFXLxVnpkpsiDxx.jpg': { uri: '/images/dAiv3qfJFXLxVnpkpsiDxx.jpg' },
'iaTahePTdq1oBJcHk15TDz.png': { uri: '/images/iaTahePTdq1oBJcHk15TDz.png' }
}

export default imageStaticSourcesByFileName
